import React, { FC, useEffect, useState } from 'react';
import { GrLicense, GrNetwork, GrStar, GrUser } from 'react-icons/gr';
import { GitHubRepoStatsScheme } from '../../schemas/external/github';
import { OpenSourceSchema } from '../../schemas/opensource';
import { formatThounsandNumber } from '../../utils/format';
import * as styles from './dynamic-repo-stats.module.scss';

export interface DynamicRepoStatsProps {
	url: string;
	context: OpenSourceSchema;
}

export const DynamicRepoStats: FC<DynamicRepoStatsProps> = ({ url, context }) => {
	const [stats, setStats] = useState<GitHubRepoStatsScheme | undefined>();

	useEffect(() => {
		const repoFragment = url.replace('https://github.com/', '');

		const run = async () => {
			const res = await fetch(`https://api.github.com/repos/${repoFragment}`, {
				headers: {
					Accept: 'application/vnd.github.v3+json',
				},
			});

			const data = await res.json();
			setStats(data);
		};

		run();
	}, [url]);

	if (!stats) {
		return <p>Loading ...</p>;
	}

	const descMoreIndicator = stats.description.length > 50 ? '...' : '';

	return (
		<>
			{stats.description && (
				<p className={styles.description}>
					{`${stats.description}`.substr(0, 50)}
					{descMoreIndicator}
				</p>
			)}
			<table className={styles.table}>
				<tbody>
					<tr>
						<td>
							<GrStar /> Stars
						</td>
						<td>{formatThounsandNumber(stats.stargazers_count)}</td>
					</tr>
					<tr>
						<td>
							<GrNetwork /> Forks
						</td>
						<td>{formatThounsandNumber(stats.forks_count)}</td>
					</tr>
					<tr>
						<td>
							<GrUser /> Role
						</td>
						<td>{context.relation}</td>
					</tr>
					{stats.license && (
						<tr>
							<td>
								<GrLicense /> License
							</td>
							<td>{stats.license.key.toUpperCase().replace('-', ' ')}</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
};

DynamicRepoStats.displayName = 'DynamicRepoStats';

export default DynamicRepoStats;
